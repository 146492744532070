import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect } from "react";
import "./users.index.css";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Card } from "antd";
import UsersToolbar from "./toolbar/toolbar.index";
import UsersTable from "./table/table.index";
import { addUser, getOrganizationUsers } from "./redux/thunks";
import AddEditUser from "./addEditUser/addEditUser.index";
import { selectIsUserModalOpen } from "./redux/selectors";
import { ITenantSaveUser } from "./redux/types";
import { setIsUserModalOpen } from "./redux/slice";
import { setSelectedKeys, setSideNavWarning } from "Layout/redux/slice";
import { selectProfile } from "Features/Profile/redux/selectors";
import { IGetProfilePayload } from "Features/Profile/redux/types";
import LocalStorageUtility from "Utilities/localstorage";
import { getProfile } from "Features/Profile/redux/thunks";
import { DefaultGUID } from "Constants";
import { ITenantFilter } from "Features/Tenants/redux/types";
import { getAllTenants } from "Features/Tenants/redux/thunks";

const User = () => {
  const dispatch = useAppDispatch();
  const isUserModalOpen = useAppSelector(selectIsUserModalOpen);
  const profile = useAppSelector(selectProfile);

  const getData = async () => {
    try {
      await getProfileData();
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const getProfileData = async () => {
    dispatch(setLoadingState(true));
    const payload: IGetProfilePayload = {
      email: LocalStorageUtility.getUserEmail() ?? "",
    };
    const response = await dispatch(getProfile(payload)).unwrap();
    if (response.organizationId) {
      await getTenants(response.organizationId);
    }
    if (!response.organizationName) {
      dispatch(setSideNavWarning(true));
    }
  };

  const saveEditUser = (values: ITenantSaveUser) => {
    dispatch(setLoadingState(true));
    dispatch(addUser(values))
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: `The user ${values.user.emailAddress} added successfully.`,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsUserModalOpen(false));
        getOrganizationUsersList();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message || "Failed to save user",
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  const getTenants = async (organizationId: string) => {
    let tenantFilter: ITenantFilter = {
      organizationId: (organizationId ?? DefaultGUID).toUpperCase(),
    };

    dispatch(setLoadingState(true));
    await dispatch(getAllTenants(tenantFilter)).unwrap();
  };

  const getOrganizationUsersList = async () => {
    try {
      dispatch(setLoadingState(true));
      await dispatch(
        getOrganizationUsers({
          organizationId: profile.organizationId,
        })
      ).unwrap();
    } catch (err: any) {
      console.error(err);
      dispatch(
        setErrorState({
          message: "Error",
          description: err.message,
          placement: "topRight",
          type: "error",
        })
      );
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  useEffect(() => {
    dispatch(setSelectedKeys(["4"]));
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (
      profile.organizationId !== DefaultGUID &&
      profile.organizationId !== ""
    ) {
      getOrganizationUsersList();
    }
  }, [profile]);

  return (
    <div className="organization-container">
      <Card style={{ height: "100%", border: "1px solid rgba(0, 0, 0, 0.10)" }}>
        <UsersToolbar />
        <UsersTable />
      </Card>
      {isUserModalOpen && <AddEditUser saveEditUser={saveEditUser} />}
    </div>
  );
};

export default User;
