import { useAppDispatch, useAppSelector } from "Store/hooks";
import { selectTenants } from "../redux/selectors";
import { columns } from "./columns/columns";
import { Table } from "antd";

const TenantsTable = () => {
  const tenantsList = useAppSelector(selectTenants);
  return (
    <Table
      style={{ padding: "0px" }}
      columns={columns}
      dataSource={tenantsList}
    />
  );
};
export default TenantsTable;
