import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { AddEditFormFieldType, ITenant } from "../redux/types";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { selectCurrentTenant, selectIsModalOpen } from "../redux/selectors";
import { setIsModalOpen } from "../redux/slice";
import { TenantConstants } from "Constants";
import { selectProfile } from "Features/Profile/redux/selectors";

const AddEditTenant = ({ saveEditTenant }: any) => {
  const dispatch = useAppDispatch();
  const currentTenant = useAppSelector(selectCurrentTenant);
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const profile = useAppSelector(selectProfile);
  const [form] = useForm();

  const onFinish = (values: any) => {
    const payload: ITenant = {
      ...values,
      serverConnectProperties: null,
      serverDbInfo: null,
      license: values.license,
      createdBy: "",
      updatedBy: "",
      createDtTm: new Date(),
      updateDtTm: new Date(),
      status: "",
      errorInfo: null,
      isActive: true,
      id: currentTenant.id,
      organizationId: profile.organizationId,
    };
    saveEditTenant({
      tenant: payload,
      email: "",
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(setIsModalOpen(false));
  };

  return (
    <Modal
      title={
        currentTenant.id === -1
          ? TenantConstants.CREATE_TENANT_TEXT
          : TenantConstants.UPDATE_TENANT_TEXT
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={
        currentTenant.id === -1
          ? TenantConstants.CREATE_TENANT_TEXT
          : TenantConstants.UPDATE_TENANT_TEXT
      }
    >
      <Form
        name="basic"
        initialValues={currentTenant}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item<AddEditFormFieldType>
          label="Name (subdomain)"
          name="name"
          rules={[{ required: true, message: "Please input your sub domain!" }]}
        >
          <Input
            disabled={currentTenant.id !== -1}
            addonBefore="https://"
            addonAfter=".astera.com"
          />
        </Form.Item>

        {/* <Form.Item<AddEditFormFieldType>
          label="License Key"
          name={["license", "key"]}
        >
          <Input disabled={currentTenant.id !== -1} />
        </Form.Item> */}
        <Form.Item<AddEditFormFieldType> label="Description" name="description">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditTenant;
