import { RootState } from '../../Store/index';

export const selectUser = (state: RootState) => state;
export const selectShowSideNav = (state: RootState) => state.LayoutReducer.showSideNav;
export const selectProfile = (state: RootState) => state.LayoutReducer.showProfile;
export const selectShowLaunchClient = (state: RootState) => state.LayoutReducer.showLaunchClient;
export const selectSelectedKeys = (state: RootState) => state.LayoutReducer.selectedKeys;
export const selectSideNavWarning = (state: RootState) => state.LayoutReducer.sideNavWarning;
export const selectWarningMessage = (state: RootState) => state.LayoutReducer.warningMessage;

