import React, { useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
import {
  DeploymentUnitOutlined,
  DesktopOutlined,
  HomeOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./sideNav.index.css";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Store/hooks";
import {
  selectSelectedKeys,
  selectSideNavWarning,
  selectWarningMessage,
} from "Layout/redux/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as OrganizationIcon } from "Assets/svgs/Organization.svg";
import { selectTenants } from "Features/Tenants/redux/selectors";
import User from "Features/Users/users.index";

const { Sider } = Layout;

const SideNav = ({ setIsMargin }: any) => {
  const navigate = useNavigate();
  const selectedKeys = useAppSelector(selectSelectedKeys);
  const selectedSideNavWarning = useAppSelector(selectSideNavWarning);
  const warningMessage = useAppSelector(selectWarningMessage);
  const tenants = useAppSelector(selectTenants);
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
    setIsMargin(!collapsed);
  };

  return (
    <Sider
      width={250}
      style={{
        backgroundColor: "#fff",
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 55,
        bottom: 0,
      }}
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={null}
      className="sideNav-div"
    >
      <Menu
        style={{ backgroundColor: "#fff" }}
        mode="inline"
        selectedKeys={selectedKeys}
      >
        <Menu.Item
          key="1"
          icon={<HomeOutlined className="menu-icon" />}
          onClick={() => {
            navigate("/Home");
          }}
        >
          Home
        </Menu.Item>

        {selectedSideNavWarning ? (
          <Menu.Item
            key="2"
            icon={<UserOutlined className="menu-icon" />}
            onClick={() => {
              navigate("/profile");
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div> Profile </div>
              <div>
                <Tooltip placement="top" title={warningMessage}>
                  <FontAwesomeIcon
                    style={{ color: "orange", height: "15px" }}
                    icon={faWarning}
                  />
                </Tooltip>
              </div>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item
            key="2"
            icon={<UserOutlined className="menu-icon" />}
            onClick={() => {
              navigate("/profile");
            }}
          >
            Profile
          </Menu.Item>
        )}

        {tenants.length === 0 ? (
          <Menu.Item
            key="3"
            icon={<UserOutlined className="menu-icon" />}
            onClick={() => {
              navigate("/tenants");
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div> Servers </div>
              <div>
                <Tooltip placement="top" title={"Please add a tenant"}>
                  <FontAwesomeIcon
                    style={{ color: "orange", height: "15px" }}
                    icon={faWarning}
                  />
                </Tooltip>
              </div>
            </div>
          </Menu.Item>
        ) : (
          <Menu.Item
            onClick={() => {
              navigate("/tenants");
            }}
            key="3"
            icon={<OrganizationIcon />}
          >
            Servers
          </Menu.Item>
        )}
         <Menu.Item
            onClick={() => {
              navigate("/users");
            }}
            key="4"
            icon={<TeamOutlined size={24} style={{ marginRight: "5px" }} />}
          >
            Users
          </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideNav;
