import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import DownloadImage from "Assets/svgs/hero.svg";

import styles from "./home.module.scss";

interface DownloadSectionProps {
  isDownloadDisabled: boolean;
}

const DownloadSection = ({ isDownloadDisabled }: DownloadSectionProps) => {
  const handleDownload = () => {
    window.open("", "_blank");
  };

  return (
    <div className={styles["home-main-inner-div"]}>
      <div className={styles["download-div"]}>
        <div className={styles["download-inner-div"]}>
          <div className={styles["download-text"]}>Get Started</div>
          <div className={styles["download-text"]}>By Downloading Client</div>
          <div style={{ marginTop: "2rem" }}>
            <Button
              className={styles["download-button"]}
              onClick={handleDownload}
              disabled={isDownloadDisabled}
              style={{ color: "white" }}
            >
              Download
              <span style={{ marginLeft: "20px" }}>
                <DownloadOutlined style={{ fontSize: "18px" }} />
              </span>
            </Button>
          </div>
        </div>
        <img src={DownloadImage} alt="" style={{ borderRadius: "10px" }} />
      </div>
    </div>
  );
};

export default DownloadSection;
