import CustomButton from "Components/Basic/button/Button.index";
import "../Auth.index.css";
import { useAppDispatch } from "Store/hooks";
import { setAuthState } from "../redux/slice";
import { AuthStateEnum, ITenantSignUpDTO } from "../redux/types";
import { postSignUp } from "../redux/thunks";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { useState } from "react";

import MicrosoftIcon from "Assets/svgs/Microsoft.svg";
import GoogleIcon from "Assets/svgs/Google.svg";

const Signup = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values: any) => {
    let signUpPayload: ITenantSignUpDTO = {
      subdomain: "values.subDomain",
      userName: values.tenant,
      description: "",
      id: -1,
      createdBy: "",
      updatedBy: "",
      createDtTm: new Date(),
      updateDtTm: new Date(),
      status: "",
      errorInfo: null,
      isActive: false,
      name: "",
      password: values.password,
      confirmPassword: values.confirmPassword,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    setIsLoading(true);
    localStorage.setItem("signUpDTO", JSON.stringify(signUpPayload));
    dispatch(postSignUp(signUpPayload))
      .unwrap()
      .then((response: any) => {
        if (!response.message) {
          dispatch(
            setErrorState({
              message: "Success",
              description: "Account Registered Successfully",
              placement: "topRight",
              type: "success",
            })
          );
        } else {
          dispatch(
            setErrorState({
              message: "Info",
              description: response.message,
              placement: "topRight",
              type: "info",
            })
          );
        }
        dispatch(setAuthState(AuthStateEnum.RESENDEMAIL));
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  type FieldType = {
    tenant?: string;
    password?: string;
    confirmPassword?: string;
  };
  return (
    <div className="auth-login-container">
      <div className="login-btn-container">
        <CustomButton type="default" classNames="signup-btn">
          <img src={MicrosoftIcon} height={20} alt="" />
          <span className="text"> Sign in with Microsoft</span>
        </CustomButton>
        <CustomButton type="default" classNames="signup-btn">
          <img src={GoogleIcon} height={20} alt="" />
          <span className="text"> Sign in with Google</span>
        </CustomButton>
      </div>
      <div style={{ textAlign: "center" }}>
        <label>{"Already have an account? "}</label>
        <label>
          <a
            style={{ textDecorationLine: "underline" }}
            onClick={() => dispatch(setAuthState(AuthStateEnum.LOGIN))}
          >
            Login
          </a>
        </label>
      </div>
    </div>
  );
};
export default Signup;
