import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorageUtility from "Utilities/localstorage";

type Props = {
  children: any;
};

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = LocalStorageUtility.getAccessToken();
    if (!accessToken) navigate("/");
  }, [navigate]);

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

export default PrivateRoute;
