import { Col, Divider, Row } from "antd";

import styles from "./home.module.scss";

const Footer = () => {
  return (
    <div className={styles["home-footer"]}>
      <Row gutter={16}>
        <Col lg={8} xs={24} sm={24} md={24} style={{ marginBottom: "30px" }}>
          <div className={styles["home-footer-element"]}>
            <div className={styles["footer-heading"]}>Resources</div>
            <div className={styles["footer-description"]}>
              Check out our documentation site!
            </div>
            <div className={styles["footer-options"]}>
              {"Getting Started with Report Models "}
            </div>
            <Divider />
            <div className={styles["footer-options"]}>
              {"How to extract Unstructured Data"}
            </div>
            <Divider />
            <div className={styles["footer-options"]}>
              {"Tips and Tricks for efficient Data Preparation"}
            </div>
            <div className={styles["see-more"]}>Explore All Resources</div>
          </div>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24} style={{ marginBottom: "30px" }}>
          <div className={styles["home-footer-element"]}>
            <div className={styles["footer-heading"]}>Training and Courses</div>
            <div className={styles["footer-description"]}>
              Check out our training and self-paced courses!
            </div>
            <div className={styles["footer-options"]}>
              {"Course: Extracting Unstructured Data "}
            </div>
            <Divider />
            <div className={styles["footer-options"]}>
              {"Webinar: Building ETL Pipelines"}
            </div>
            <Divider />
            <div className={styles["footer-options"]}>
              {"Course: API Management"}
            </div>
            <div className={styles["see-more"]}>
              Explore Training and Courses
            </div>
          </div>
        </Col>
        <Col lg={8} xs={24} sm={24} md={24}>
          <div className={styles["home-footer-element"]}>
            <div className={styles["footer-heading"]}>Astera Data Stack</div>
            <div className={styles["footer-description"]}>
              Explore our other offerings!{" "}
            </div>
            <div className={styles["footer-options"]}>
              {"Embracing the AI-driven Automation Era "}
            </div>
            <Divider />
            <div className={styles["footer-options"]}>{"By Industry"}</div>
            <Divider />
            <div className={styles["footer-options"]}>
              {"Customer Testimonials"}
            </div>
            <div className={styles["see-more"]}>Explore Astera Data Stack</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
