import { useAppDispatch, useAppSelector } from "Store/hooks";
import { useEffect, useState } from "react";
import { getAllTenants, saveTenant, updateTenant } from "./redux/thunks";
import { ITenant, ITenantFilter, SaveTenantType } from "./redux/types";
import TenantsTable from "./table/table.index";
import "./tenants.index.css";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import TenantToolbar from "./toolbar/toolbar.index";
import AddEditTenant from "./addEditTenant/addEditTenant.index";
import { selectIsModalOpen } from "./redux/selectors";
import { setIsModalOpen } from "./redux/slice";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Card } from "antd";
import { DefaultGUID, TenantConstants } from "Constants";
import { setSelectedKeys, setSideNavWarning } from "Layout/redux/slice";
import { selectProfile } from "Features/Profile/redux/selectors";
import { IGetProfilePayload } from "Features/Profile/redux/types";
import { getProfile } from "Features/Profile/redux/thunks";
import LocalStorageUtility from "Utilities/localstorage";

const Tenants = () => {
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const profile = useAppSelector(selectProfile);
  
  useEffect(() => {
    dispatch(setSelectedKeys(["3"]));
    getData();
  }, []);
  
  const getData = async () => {
    await getProfileData();
  };
  
  const getProfileData = async () => {
    try {
      dispatch(setLoadingState(true));
      const payload: IGetProfilePayload = {
        email: LocalStorageUtility.getUserEmail() ?? "",
      };
      const response = await dispatch(getProfile(payload)).unwrap();
      
      if (!response.organizationName) {
        dispatch(setSideNavWarning(true));
      }
  
      if (response.organizationId) {
        getTenants(response.organizationId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoadingState(false));
    }
  };
  
  const getTenants = (organizationId: string) => {
    let tenantFilter: ITenantFilter = {
      organizationId: (organizationId ?? DefaultGUID).toUpperCase(),
    };
  
    dispatch(setLoadingState(true));
    dispatch(getAllTenants(tenantFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  
  const saveEditTenant = (values: SaveTenantType, configureRepo: boolean = false) => {
    dispatch(setLoadingState(true));
    dispatch(
      values.tenant.id === -1
        ? saveTenant(values)
        : updateTenant({ tenant: values, configureRepo })
    )
      .unwrap()
      .then((response) => {
        dispatch(
          setErrorState({
            message: "Success",
            description: TenantConstants.TENANT_SAVED_MESSAGE,
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsModalOpen(false));
        getTenants(profile.organizationId);
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  return (
    <div className="tenants-container">
      <Card style={{ height: "100%", border: "1px solid rgba(0, 0, 0, 0.10)" }}>
        <TenantToolbar />
        <TenantsTable />
      </Card>
      {isModalOpen && <AddEditTenant saveEditTenant={saveEditTenant} />}
    </div>
  );
};

export default Tenants;
