import { Description } from "Components/Custom/Description";
import { ColumnsType } from "antd/es/table";
import { LicenseInfoRenderer } from "./licenseInfoRenderer";
import { ActionRenderer } from "./actionRenderer";
import "Features/Tenants/tenants.index.css";
import { Tag } from "antd";

export const renderActiveStatus = (isActive: boolean) =>
  isActive ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;

export const columns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "10%",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text: string) =>
      text ? <Description text={text} maxLimit={100} /> : "N/A",
    width: "15%",
  },
  {
    title: "License",
    dataIndex: "license",
    key: "license",
    render: LicenseInfoRenderer,
    width: "25%",
  },
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: renderActiveStatus,
    width: "15%",
  },
  {
    title: "Organization",
    dataIndex: "organizationName",
    key: "organizationName",
    width: "15%",
    render: (value: string) => value || "N/A",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
    align: "center",
    render: ActionRenderer,
  },
];
