export const msalConfig = {
  auth: {
    clientId: "3fce6e28-21f1-41ae-8cb1-713a56318322",
    authority:
      "https://login.microsoftonline.com/3ec51fe7-5201-43bd-910e-db69cc9e9d65/v2.0", // Azure AD tenant ID
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const azureScope = [
  "https://cloudastera.com/access_as_user offline_access",
];
