export type AuthenticationStateType = {
  authState: AuthStateEnum;
  loginState: number;
};
export interface LoginModel {
  User: string;
  Password: string;
  RememberMe: boolean;
}

export enum AuthStateEnum {
  SIGNUP,
  LOGIN,
  RESENDEMAIL,
}
export interface ITenantInfo {
  description: string;
  serverConnectProperties: any;
  serverDbInfo: any;
  license: any;
  id: string;
  createdBy: string;
  updatedBy: string;
  createDtTm: Date;
  updateDtTm: Date;
  status: string;
  errorInfo: any;
  isActive: boolean;
  name: string;
}
export interface ITenantSignUpDTO {
    subdomain?: string | null;
    userName: string;
    description: string;
    serverConnectProperties?: any;
    serverDbInfo?: any;
    license?: any;
    id: number;
    createdBy: string;
    updatedBy: string;
    createDtTm: Date;
    updateDtTm: Date;
    status: string;
    errorInfo: any;
    isActive: boolean;
    name: string;
    password : string;
    confirmPassword : string;
    email : string;
    firstName : string;
    lastName : string;
}
  
export interface ILoginResponse {
  accessToken: string,
  
}
export enum Platform {
  Centerprise = "Centerprise",
  DeveloperPortal = "DeveloperPortal",
  SelfServicePortal = "SelfServicePortal",
  TenantManagementPortal = "TenantManagementPortal"
}