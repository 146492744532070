import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILayout } from "./types";

const initialState: ILayout = {
  showSideNav: true,
  showProfile: true,
  showLaunchClient: true,
  sideNavWarning: false,
  warningMessage: "",
  selectedKeys: ["1"],
};

export const layout = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setShowProfile: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        showProfile: action.payload,
      };
    },
    setShowLaunchClient: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        showLaunchClient: action.payload,
      };
    },
    setShowSideNav: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        showSideNav: action.payload,
      };
    },
    setSelectedKeys: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedKeys: action.payload,
      };
    },
    setSideNavWarning: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        sideNavWarning: action.payload,
      };
    },
    setWarningMessage: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        warningMessage: action.payload,
      };
    },
  },
  extraReducers(builder) {},
});
export const {
  setSelectedKeys,
  setShowLaunchClient,
  setShowProfile,
  setShowSideNav,
  setSideNavWarning,
  setWarningMessage
} = layout.actions;
export default layout.reducer;
