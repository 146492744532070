//Auth -->> Login
export const AUTH_LOGO_TEXT = "Empowering You to Take Control – Manage Your Services Seamlessly";
export const LOGIN_HEADING_TEXT = "User Login"
export const LOGIN_SUB_HEADING = "Enter your details to sign in to your account"
export const LOGIN_BUTTON_TEXT = "LOGIN"
export const REGISTER_BUTTON_TEXT = "REGISTER"

export const LOGIN_BUTTON_LOADING_TEXT = "LOADING..."
//Auth -->> Server registration
export const SERVER_REGISTRATION_HEADING_TEXT = "Server Connection"
export const SERVER_REGISTRATION_SUB_HEADING = "Enter your details to get sign in to your account"
export const SERVER_REGISTRATION_BUTTON_TEXT = "CONNECT"
export const SERVER_REGISTRATION_LOADING_TEXT = "LOADING..."

export const TenantConstants = {
    CREATE_TENANT_TEXT : "Create Tenant",
    UPDATE_TENANT_TEXT : "Update Tenant",
    ADD_TENANT : "Add Tenant",
    TENANT_SAVED_MESSAGE : "Tenant saved successfully"
}

export const OrganizationConstants = {
    CREATE_ORGANIZATION_TEXT : "Create Organization",
    UPDATE_ORGANIZATION_TEXT : "Update Organization",
    ADD_ORGANIZATION : "Add Organization",
    ORGANIZATION_SAVED_MESSAGE : "Organization saved successfully",
    ORGANIZATION_DELETD_MESSAGE : "Organization deleted successfully",
    NAME_VALIDATION : "Please input your organization name!",
    ORGANIZATION_DELETION_VERIFICATION_TEXT : "You won't be able to recover deleted organization and respective tenants",
    ORGANIZATION_DELETION_TITLE_TEXT : "Are you sure?",
    DELETE_ORGANIZATION_TEXT : "Delete",
    DEACTIVATE_ORGANIZATION_TEXT : "Deactivate",
    ACTIVATE_ORGANIZATION_TEXT : "Activate",
    EDIT_ORGANIZATION_TEXT : "Edit"

}

export const DefaultGUID = "00000000-0000-0000-0000-000000000000"
export const CENTERPRISE_DOWNLOAD_URL = "https://asterasoftware.sharepoint.com/:u:/s/ProductionWebsite/EZbtS9SEXahNuy74-J5uOCMBQF9xIGAzEDD12RAONWvGLQ?download=1"
export const ASTERA_DOWNLOAD_CENTER_URL = "https://www.astera.com/download-center/"