import Logo from "../../Assets/images/centerprise_logo.png";
import "./Auth.index.css";
import { AUTH_LOGO_TEXT } from "../../Constants";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import moment from "moment";
import Signup from "./Signup/Signup.index";
import { AuthStateEnum } from "./redux/types";
import Login from "./Login/Login.index";
import ResendEmail from "./ResendEmail/resendEmail";
import LockIcon from "Assets/svgs/Lock.svg";
import AsteraLogo from "Assets/svgs/AsteraLogo.svg";

const Authentication = () => {
  const authState = useSelector(
    (state: RootState) => state.AuthenticationReducer.authState
  );
  const getComponent = () => {
    if (authState === AuthStateEnum.SIGNUP) return <Signup />;
    else if (authState === AuthStateEnum.RESENDEMAIL) return <ResendEmail />;
    else return <Login />;
  };

  const getYear = () => {
    return moment().year();
  };
  return (
    <div className="connection_container">
      <div className="header">
        <div className="left_container">
          <img alt="logo" src={AsteraLogo} className="logo" />
          <span className="main_heading">Self Service Portal</span>
          <p className="auth_logo_text">{AUTH_LOGO_TEXT}</p>
        </div>
        <div className="right_container">
          <div className="login_container">
            <div className="connection_wrapper">
              <div className="stepper_container">
                <img src={LockIcon} alt="" />
                <span className="heading">
                  {authState === AuthStateEnum.SIGNUP ||
                  authState === AuthStateEnum.RESENDEMAIL
                    ? "Sign Up"
                    : "Login to Continue"}
                </span>
              </div>
              <div className="connection_form_wrapper">{getComponent()}</div>
              <div className="privacy-policy-container">
                <img src={AsteraLogo} alt="" />
                <span className="text">Manage your data with zero-code AI interface</span>
                  <span className="sub-text">Privacy Policy | Terms of Use</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <span>{`Copyright (C) ${getYear()} Astera Software. All rights reserved.`}</span>
      </div>
    </div>
  );
};

export default Authentication;
