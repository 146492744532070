import { useAppSelector } from "Store/hooks";
import { selectUserList } from "../redux/selectors";
import { columns } from "./columns/columns";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { ITenantUser, OrganizationUserType } from "../redux/types";

const UsersTable = () => {
  const tenantUserList = useAppSelector(selectUserList);
  const [tenantUsers, setTenantUsers] = useState<ITenantUser[]>([]);

  const flattenedData = (data: OrganizationUserType[]) =>
    data.flatMap((tenant: OrganizationUserType) =>
      tenant.users.map((user) => ({
        tenantName: tenant.tenantName,
        userName: user.userName,
        emailAddress: user.emailAddress,
        isLockedOut: user.isLockedOut
      }))
    );

  useEffect(() => {
    const tenantUsers = flattenedData(tenantUserList);
    setTenantUsers(tenantUsers);
  }, [tenantUserList]);
  return (
    <Table
      style={{ padding: "0px" }}
      columns={columns}
      dataSource={tenantUsers}
    />
  );
};
export default UsersTable;
