export type IUserState = {
  tenantUserList: OrganizationUserType[];
  currentUser: ITenantSaveUser;
  isUserModalOpen: boolean;
};

export type OrganizationUserType = {
  tenantName: string;
  users: IUser[];
};

export enum Platform {
  Centerprise = "Centerprise",
  DeveloperPortal = "DeveloperPortal",
  SelfServicePortal = "SelfServicePortal",
  TenantManagementPortal = "TenantManagementPortal",
}

export enum UserAuthenticationProvider {
  Centerprise = "Centerprise",
  WindowsAuthentication = "WindowsAuthentication",
  AzureAuthentication = "AzureAuthentication",
}

export interface IUser {
  id?: string;
  name: string;
  userName: string;
  emailAddress: string;
  normalizedEmailAddress: string;
  isActive: boolean;
  platform: Platform;
  emailConfirmed: boolean;
  isLockedOut: boolean;
  xmlData: string | null;
  UserAuthenticationProvider: UserAuthenticationProvider;
}

export interface ITenantUser{
  userName: string,
  emailAddress: string,
  tenantName: string
  isLockedOut: boolean
}

export interface IGetOrganizationUserRequest {
  organizationId: string;
}

export interface ITenantSaveUser{
  tenantName: string,
  user: IUser
}