import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorageUtility from "Utilities/localstorage";

// Typescript Props
type Props = {
  children: any;
};

const PublicRoute = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = LocalStorageUtility.getAccessToken();
    if (accessToken) navigate("/Home");
  }, []);

  return children;
};

// PropTypes
PublicRoute.propTypes = {
  children: PropTypes.any,
};

export default PublicRoute;
