import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "Store/hooks";
import { Button } from "antd";
import { setCurrentTenant, setIsModalOpen } from "../redux/slice";
import { initialValues } from "../addEditTenant/intialValues";
import { TenantConstants } from "Constants";
import "Features/Tenants/tenants.index.css";

const TenantToolbar = () => {
  const dispatch = useAppDispatch();
  return (
    <div
      style={{justifyContent:"space-between", display: "flex", flexDirection: "row-reverse", margin: "5px",alignItems:'center' }}
    >
      <Button
        type="primary"
        className="add-tenant-button"
        onClick={() => {
          dispatch(setCurrentTenant(initialValues));
          dispatch(setIsModalOpen(true));
        }}
      >
        <FontAwesomeIcon style={{ marginRight: "10px" }} icon={faPlus} />
        {TenantConstants.ADD_TENANT}
      </Button>
      <div className="tenant-heading">Your Servers</div>
    </div>
  );
};

export default TenantToolbar;
