import { useEffect, useState } from "react";
import { useAppDispatch } from "Store/hooks";
import {
  setSelectedKeys,
  setShowLaunchClient,
  setShowProfile,
  setShowSideNav,
  setSideNavWarning,
} from "Layout/redux/slice";
import { getOrganizationAndTenantStatus } from "./redux/thunks";
import { OrganizationAndTenantStatusType } from "./redux/types";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import { IGetProfilePayload } from "Features/Profile/redux/types";

import WarningBanner from "./warningBanner";
import DownloadSection from "./downloadSection";
import Footer from "./footer";

import styles from "./home.module.scss";
import { getProfile } from "Features/Profile/redux/thunks";
import { ITenantFilter } from "Features/Tenants/redux/types";
import { getAllTenants } from "Features/Tenants/redux/thunks";
import { DefaultGUID } from "Constants";
import LocalStorageUtility from "Utilities/localstorage";
import { ILocalStorageInfo } from "Common/types";

const Home = () => {
  const dispatch = useAppDispatch();
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);

  useEffect(() => {
    dispatch(setShowLaunchClient(true));
    dispatch(setShowProfile(true));
    dispatch(setShowSideNav(true));
    dispatch(setSelectedKeys(["1"]));
    getStatus();
    getProfileData();
  }, []);

  const getStatus = () => {
    dispatch(setLoadingState(true));
    const userInfo : ILocalStorageInfo = LocalStorageUtility.getUserInfoAndToken();

    const payload: IGetProfilePayload = {
      email: userInfo?.user?.emailAddress ?? "",
    };

    dispatch(getOrganizationAndTenantStatus(payload))
      .unwrap()
      .then((response: OrganizationAndTenantStatusType) => {
        if (!response.isOrganizationCreated) {
          dispatch(setSideNavWarning(true));
          setIsDownloadDisabled(true);
          return;
        }

        if (!response.isTenantCreated) {
          setIsDownloadDisabled(true);
          return;
        }

        dispatch(setSideNavWarning(false));
        setIsDownloadDisabled(false);
      })
      .catch(() => {})
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const getProfileData = () => {
    dispatch(setLoadingState(true));
    const userInfo : ILocalStorageInfo = LocalStorageUtility.getUserInfoAndToken();

    const payload: IGetProfilePayload = {
      email: userInfo?.user?.emailAddress ?? "",
    };
    dispatch(getProfile(payload))
      .unwrap()
      .then(async (response: any) => {
        if (!response.organizationName) {
          dispatch(setSideNavWarning(true));
        }
        await getTenants(response.organizationId);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const getTenants = async (organizationId: string) => {
    let tenantFilter: ITenantFilter = {
      organizationId: (organizationId ?? DefaultGUID).toUpperCase(),
    };

    dispatch(setLoadingState(true));
    await dispatch(getAllTenants(tenantFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  return (
    <div className={styles["home-main-div"]}>
      {isDownloadDisabled && <WarningBanner />}
      <DownloadSection isDownloadDisabled={isDownloadDisabled} />
      <Footer />
    </div>
  );
};

export default Home;
