import Authentication from "Features/Auth/Auth.index";
import EmailConfirmation from "Features/Auth/EmailConfirmation/emailConfirmation.index";
import TenantCreatedPage from "Features/Auth/TenantSuccess/tenantSucces.index";
import Home from "Features/Home/home.index";
import LaunchClient from "Features/LaunchClient/launchClient.index";
import Profile from "Features/Profile/profile.index";
import Tenants from "Features/Tenants/tenants.index";
import User from "Features/Users/users.index";
import MainLayout from "Layout/layout.index";
interface IPrivateRoutes {
	path: string;
	element: any;
	layout?: any;
}
export const PRIVATE_ROUTES : IPrivateRoutes[] = [
	{
		path: "/Home",
		element: Home,
		layout : MainLayout
	},
	{
		path: "/launch",
		element: LaunchClient,
		layout : MainLayout
	},
	{
		path: "/profile",
		element: Profile,
		layout : MainLayout
	},
	{
		path: "/tenants",
		element: Tenants,
		layout : MainLayout
	},
	{
		path : '/users',
		element : User,
		layout : MainLayout
	},
];

export const PUBLIC_ROUTES : any[]= [
	{
		path: "/",
		element: Authentication,
	},
	{
		path: "success",
		element: TenantCreatedPage 
	},
	{
		path: "verify",
		element: EmailConfirmation
	}
];
