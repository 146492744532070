import {
  setShowLaunchClient,
  setShowProfile,
  setShowSideNav,
} from "Layout/redux/slice";
import { useAppDispatch } from "Store/hooks";
import { useEffect } from "react";
import "./launchClient.index.css";
import LeaveWebIcon from "Assets/svgs/leave_web.svg";
import { Button } from "antd";
import { CENTERPRISE_DOWNLOAD_URL } from "Constants";

const LaunchClient = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setShowLaunchClient(false));
    dispatch(setShowProfile(false));
    dispatch(setShowSideNav(false));
    window.location.href = "centerprise::open";
  }, []);

  const launchCenterprise = () => {
    const centerpriseUrl = "centerprise::open";

    let isCenterpriseOpened = false;

    try {
      window.location.href = centerpriseUrl;

      setTimeout(() => {
        if (!isCenterpriseOpened) {
          if (
            window.confirm(
              "Unable to find Centerprise. Please download and install it, then try again. Do you want to download it now?"
            )
          ) {
            window.location.href = CENTERPRISE_DOWNLOAD_URL;
          }
        }
      }, 1000);
    } catch (error) {
      if (
        window.confirm(
          "Unable to find Centerprise. Please download and install it, then try again. Do you want to download it now?"
        )
      ) {
        window.location.href = CENTERPRISE_DOWNLOAD_URL;
      }
    }

    window.addEventListener("blur", () => {
      isCenterpriseOpened = true;
    });
  };

  return (
    <div className="launch-client-main-div">
      <img src={LeaveWebIcon} alt="" />
      <div style={{ marginTop: "1.5rem" }}>
        <div className="launch-client-main-text">
          You are about to leave the <b>Astera Cloud Website.</b>
        </div>
        <div className="launch-client-main-text">
          Click below to launch the <b>external client</b> and start the
          creation process.
        </div>
      </div>
      <Button
        className="launch-client-button"
        onClick={() => {
          launchCenterprise();
        }}
      >
        Launch Client
      </Button>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span className="launch-client-footer-text-black">
            Don’t have the Client installed?
          </span>
          <span className="launch-client-footer-text-blue">
            {" Download here"}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <span className="launch-client-footer-text-black">
            Want to learn more about the Astera Data Stack?
          </span>
          <span className="launch-client-footer-text-blue">
            {" Click here"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LaunchClient;
