import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postRequest
} from "../../../Utilities/ApiHelpers/ApiHelpers.index";
import { IGetProfilePayload, ProfileSaveRequest } from "./types";
import { getProfileIdentifier, saveProfileIdentifier } from "./indentifier";


export const getProfile = createAsyncThunk(
  getProfileIdentifier,
  async (getProfilePayload: IGetProfilePayload) => {
    const response = await postRequest(
      getProfileIdentifier,
      null,
      true,
      getProfilePayload,
      null
    );
    return response.data;
  }
);

export const saveProfile = createAsyncThunk(
  saveProfileIdentifier,
  async (payload: ProfileSaveRequest) => {
    const response = await postRequest(
      saveProfileIdentifier,
      payload.params,
      true,
      payload.data,
      null
    );
    return response.data;
  }
);