export const LoginURL = "MultiTenant/Login";
export const LogoutURL = "/api/account/Logout";
export const CreateTenantURL = "MultiTenant/TenantSignUp"
export const SignUpURL = "MultiTenant/Register"
export const verifyEmailURL = "MultiTenant/Verify"
export const VerificationLinkURL = "MultiTenant/SendVerificationEmail"

export const multitenantBaseURL = "/MultiTenant";

export const multitenantRoutes = {
    login : `${multitenantBaseURL}/Login`,
    logout : `${multitenantBaseURL}/Logout`,  
    allTenants : `${multitenantBaseURL}/AllTenantsWithOrganization`,
    saveTenant : `${multitenantBaseURL}/TenantInfo`,
    updateTenant : `${multitenantBaseURL}/UpdateTenantInfo`,
    deleteTenant : `${multitenantBaseURL}/DeleteTenantInfo`,
    getOrganizations : `${multitenantBaseURL}/GetOrganizations`,
    organizations : `${multitenantBaseURL}/Organization`,
    checkMultiTenantDb : `${multitenantBaseURL}/CheckMultiTenantRepoExistence`,
    setupMultiTenantDbAndDefaultTenant : `${multitenantBaseURL}/AddMultiTenantRepoAndDefaultTenant`,
    AuthenticateAzureUser: `${multitenantBaseURL}/AuthenticateAzureUser`,
    GetAllUsers: `${multitenantBaseURL}/GetAllUsers`,
    AddUser: `${multitenantBaseURL}/AddUserInTenant`,
    GetOrganizationUsers: `${multitenantBaseURL}/GetOrganizationUsers`
  };
  