import { createAsyncThunk } from "@reduxjs/toolkit";
import { multitenantRoutes } from "../../../Constants/api";
import {
  deleteRequest,
  postRequest,
} from "../../../Utilities/ApiHelpers/ApiHelpers.index";
import { ITenant, ITenantFilter, SaveTenantType } from "./types";
import { Platform } from "Features/Auth/redux/types";

export const getAllTenants = createAsyncThunk(
  multitenantRoutes.allTenants,
  async (tenantFilter: ITenantFilter) => {
    const response = await postRequest(
      multitenantRoutes.allTenants,
      null,
      true,
      tenantFilter,
      null
    );
    return response.data;
  }
);

export const saveTenant = createAsyncThunk(
  multitenantRoutes.saveTenant,
  async (payload: SaveTenantType) => {
    const data: SaveTenantType = {
      tenant: payload.tenant,
      email: payload.email,
      platform: Platform.SelfServicePortal,
    };
    const response = await postRequest(
      multitenantRoutes.saveTenant,
      null,
      true,
      data,
      null
    );
    return response.data;
  }
);

export const updateTenant = createAsyncThunk(
  multitenantRoutes.updateTenant,
  async ({ tenant, configureRepo }: any) => {
    const response = await postRequest(
      multitenantRoutes.updateTenant,
      { configureRepo },
      true,
      tenant,
      null
    );
    return response.data;
  }
);

export const deleteTenant = createAsyncThunk(
  multitenantRoutes.deleteTenant,
  async ({ name, isConfigure }: any) => {
    const response = await deleteRequest(
      `${multitenantRoutes.deleteTenant}/${name}/${isConfigure}`,
      null,
      true,
      null
    );
    return response.data;
  }
);
