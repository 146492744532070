import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITenantSaveUser, IUserState, OrganizationUserType } from "./types";
import { getOrganizationUsers } from "./thunks";
import { initialTenantUser } from "../addEditUser/intialValues";

const initialState: IUserState = {
  tenantUserList: [],
  currentUser: initialTenantUser,
  isUserModalOpen: false,
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setTenantUserList: (state, action: PayloadAction<OrganizationUserType[]>) => {
      return {
        ...state,
        tenantUserList: action.payload,
      };
    },
    setCurrentUser: (state, action: PayloadAction<ITenantSaveUser>) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    setIsUserModalOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state, 
        isUserModalOpen: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrganizationUsers.fulfilled, (state, action) => {
      state.tenantUserList = action.payload;
    });
  },
});

export const { setTenantUserList, setCurrentUser, setIsUserModalOpen } = users.actions;
export default users.reducer;
