import { Alert } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.scss";

const WarningBanner = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["home-warning-div"]}>
      <Alert
        message="Please complete the profile to download the Client."
        banner
      />
    </div>
  );
};

export default WarningBanner;
