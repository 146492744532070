import {
  UserAuthenticationProvider,
  Platform,
  ITenantSaveUser,
} from "../redux/types";

export const initialTenantUser: ITenantSaveUser = {
  tenantName: "",
  user: {
    id: "-1",
    name: "",
    userName: "",
    emailAddress: "",
    normalizedEmailAddress: "",
    isActive: true,
    platform: Platform.TenantManagementPortal,
    emailConfirmed: true,
    isLockedOut: false,
    xmlData: null,
    UserAuthenticationProvider: UserAuthenticationProvider.AzureAuthentication,
  },
};
