import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Startup";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "Store";
import ErrorBoundary from "Components/Basic/ErrorBoundary/ErrorBoundary.index";
import { ConfigProvider } from "antd";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "Config/authConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ConfigProvider theme={{ token: { fontFamily: "Poppins" } }}>
        <Provider store={store}>
          <BrowserRouter>
            <ErrorBoundary />
            <App />
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
