import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import "Features/Tenants/tenants.index.css";

export const renderActiveStatus = (isLocked: boolean) => 
  !isLocked ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;

export const columns: ColumnsType<any> = [
  {
    title: "Username",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "Email",
    dataIndex: "emailAddress",
    key: "emailAddress",
  },
  {
    title: "Active",
    dataIndex: "isLockedOut",
    key: "isLockedOut",
    render: renderActiveStatus,
  },
  {
    title: "Server",
    dataIndex: "tenantName",
    key: "tenantName",
    align: "center",
  },
];
