import axios from "axios";
import { forEach } from "lodash";
import { acquireToken, isTokenExpiringSoon } from "./tokenHelper";
import LocalStorageUtility from "Utilities/localstorage";

const Axios = axios.create();

const PUBLIC_API_URLS = ["/AuthenticateAzureUser"];

let isTokenRefreshingBeforeRequest = false;
let isTokenRefreshingAfterRequest = false;
let pendingRequests: any = [];
const MAX_RETRY_COUNT = 3;

function processPendingRequests(newToken: string) {
  pendingRequests.forEach(({ resolve }: any) => resolve(newToken));
  pendingRequests = [];
}

function addPendingRequest(resolve: any, reject: any) {
  pendingRequests.push({ resolve, reject });
}

export const setupAxiosInterceptors = (navigate: any) => {
  Axios.interceptors.request.use(
    async (config: any) => {
      if (!PUBLIC_API_URLS.some((url) => config.url.includes(url))) {
        if (isTokenExpiringSoon() && !isTokenRefreshingBeforeRequest) {
          isTokenRefreshingBeforeRequest = true;
          try {
            const token = await acquireToken();
            if (token) {
              LocalStorageUtility.setAccessToken(token);
              const decodedToken = JSON.parse(atob(token.split(".")[1]));
              const expiryTime = decodedToken.exp * 1000;
              LocalStorageUtility.setTokenExpiry(expiryTime.toString());
              config.headers.Authorization = `Bearer ${token}`;
            }
          } catch (error) {
            console.error("Failed to acquire token before request:", error);
            return Promise.reject(error);
          } finally {
            isTokenRefreshingBeforeRequest = false;
          }
        }
      }
      return config;
    },
    (error) => {
      console.error("Request setup failed:", error);
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;

      if (error.response) {
        if (error.response.status === 401) {
          originalRequest._retryCount = originalRequest._retryCount || 0;

          if (originalRequest._retryCount >= MAX_RETRY_COUNT) {
            setTimeout(() => {
              LocalStorageUtility.deleteUserInfoAndToken();
              navigate("/");
            }, 2000);

            return Promise.reject("Your session has expired. Please login again.");
          }

          originalRequest._retryCount += 1;

          if (!isTokenRefreshingAfterRequest) {
            isTokenRefreshingAfterRequest = true;
            try {
              const newToken = await acquireToken();
              if (newToken) {
                LocalStorageUtility.setAccessToken(newToken);
                const decodedToken = JSON.parse(atob(newToken.split(".")[1]));
                const expiryTime = decodedToken.exp * 1000;
                LocalStorageUtility.setTokenExpiry(expiryTime.toString());

                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                processPendingRequests(newToken);
                return Axios(originalRequest);
              } else {
                throw new Error("Failed to acquire new token");
              }
            } catch (refreshError) {
              console.error("Failed to refresh token:", refreshError);
              LocalStorageUtility.deleteUserInfoAndToken();
              navigate("/");
              return Promise.reject(refreshError);
            } finally {
              isTokenRefreshingAfterRequest = false;
            }
          }

          return new Promise((resolve, reject) => {
            addPendingRequest(resolve, reject);
          }).then((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return Axios(originalRequest);
          });
        }

        if (
          error.response.status === 500 &&
          !LocalStorageUtility.getAccessToken()
        ) {
          LocalStorageUtility.deleteUserInfoAndToken();
          navigate("/"); 
          return Promise.reject("Server error, redirecting to login.");
        }

        if (error.response.data.Message) {
          throw error.response.data.Message;
        } else {
          throw error.response.data;
        }
      }

      // Handle network errors
      else if (
        error.request &&
        error.message &&
        error.message.toLowerCase() === "network error"
      ) {
        throw "The server is currently unavailable, or the SSL certificate is not properly installed. Please ensure that the server is running, the certificate is installed correctly, and try again.";
      }

      throw error.response || error;
    }
  );
};

const createParams = (listOfParams: any) => {
  let array: any = [];
  forEach(listOfParams, (paramValue: any, paramKey: any) => {
    array.push(
      encodeURIComponent(paramKey) + "=" + encodeURIComponent(paramValue)
    );
  });
  return array.join("&");
};

const buildUrl = (endpointUrl: string, params: any, apiType = null) => {
  const firstEndpointUrlChar = endpointUrl.charAt(0);
  const addSlash = firstEndpointUrlChar === "/" ? "" : "/";
  var api = getAPIUrl();
  let url = api + addSlash + endpointUrl;
  url = !url.endsWith("/") ? url + "/" : url;
  url = params ? url + "?" + createParams(params) : url;
  return url;
};

const fetchToken = (passedToken?: boolean) => {
  const token = LocalStorageUtility.getAccessToken();
  return token;
};

export const testConnection = (url: string) => {
  return Axios.get(url);
};

export const getRequest = (
  url: string,
  params = null,
  hasHeaders: boolean = true
) => {
  const token = fetchToken();
  return Axios.get(
    `${buildUrl(url, params)}`,
    hasHeaders
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : undefined
  );
};

export const postRequest = (
  url: string,
  params: any = null,
  hasHeaders: boolean,
  data: any,
  apiType: any = null
) => {
  const token = fetchToken();
  return Axios.post(
    `${buildUrl(url, params, apiType)}`,
    data,
    hasHeaders
      ? {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      : undefined
  );
};

export const putRequest = (
  url: string,
  params = null,
  hasHeaders: boolean,
  data: any,
  apiType = null
) => {
  const token = fetchToken();
  return Axios.put(
    `${buildUrl(url, params, apiType)}`,
    { ...data },
    hasHeaders
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : undefined
  );
};

export const deleteRequest = (
  url: string,
  params: any = null,
  hasHeaders: boolean,
  data: any
) => {
  const token = fetchToken();
  return Axios.delete(`${buildUrl(url, params)}`, {
    headers: hasHeaders
      ? {
          Authorization: "Bearer " + token,
        }
      : undefined,
    data: data,
  });
};

export const patchRequest = (
  url: string,
  params = null,
  hasHeaders: boolean,
  data: any
) => {
  const token = fetchToken();
  return Axios.patch(
    `${buildUrl(url, params)}`,
    { ...data },
    hasHeaders
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : undefined
  );
};

export const postRequestWithAbort = (
  url: string,
  params: any = null,
  hasHeaders: boolean,
  data: any,
  apiType: any = null,
  signal: any
) => {
  const token = fetchToken();
  return Axios.post(`${buildUrl(url, params, apiType)}`, data, {
    headers: hasHeaders
      ? {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }
      : undefined,
    signal: signal,
  });
};

export const getAPIUrl = () => {
  return process.env.REACT_APP_API_URL;
};