import { createAsyncThunk } from "@reduxjs/toolkit";
import { multitenantRoutes } from "../../../Constants/api";
import { getRequest, postRequest } from "Utilities/ApiHelpers/ApiHelpers.index";
import { IGetOrganizationUserRequest, ITenantSaveUser } from "./types";

export const getOrganizationUsers = createAsyncThunk(
  multitenantRoutes.GetOrganizationUsers,
  async (params: IGetOrganizationUserRequest) => {
    const response = await getRequest(multitenantRoutes.GetOrganizationUsers, params as any);
    return response.data;
  }
);

export const addUser = createAsyncThunk(
  multitenantRoutes.organizations,
  async (user: ITenantSaveUser) => {
    const response = await postRequest(
      multitenantRoutes.AddUser,
      null,
      true,
      user,
      null
    );
    return response.data;
  }
);