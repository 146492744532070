import EnvelopeIcon from "Assets/svgs/Envelope.svg";
import "./resendEmail.css";
import { Button, Spin } from "antd";
import { useAppDispatch } from "Store/hooks";
import { AuthStateEnum } from "../redux/types";
import { setAuthState } from "../redux/slice";
import { sendVerificationEmail } from "../redux/thunks";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
const ResendEmail = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(59);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const startTimer = () => {
    setButtonDisabled(true); 
    setTimer(59); 
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1); 
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setButtonDisabled(false); 
    }
  }, [timer]);
  const handleSendVerificationEmail = () => {
    setIsLoading(true);
    dispatch(
      sendVerificationEmail(JSON.parse(localStorage.getItem("signUpDTO") ?? ""))
    )
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description:
              "Email has been sent successfully, Please verify your account",
            placement: "topRight",
            type: "success",
          })
        );
      })
      .catch((err: any) => {})
      .finally(() => {
        setIsLoading(false);
        startTimer();
      });
  };
  return (
    <>
      <div className="resend-email-container">
        <img src={EnvelopeIcon} height={200} alt="" />
        <div className="thank-you-text">Thank you for registering!</div>
        <div className="thank-you-description">
          {" "}
          To complete your account setup, check your inbox for a verification
          link.
        </div>
        <Button
          className="resend-email-button"
          onClick={handleSendVerificationEmail}
          disabled={buttonDisabled}
        >
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />}
            />
          ) : (
            "Resend Link"
          )}
        </Button>
        {timer > 0 && <p style={{color:"#1568C7"}}>Retry in 00:{timer} seconds</p>}
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <label>{"Already have an account? "}</label>
          <label>
            <a
              style={{ textDecorationLine: "underline" }}
              onClick={() => dispatch(setAuthState(AuthStateEnum.LOGIN))}
            >
              Login
            </a>
          </label>
        </div>
      </div>
    </>
  );
};

export default ResendEmail;
