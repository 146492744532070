import React from "react";
import { Menu, Dropdown, Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./topNav.index.css";
import AsteraLogo from "Assets/images/AsteraLogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "Store/hooks";
import {
  selectProfile,
  selectShowLaunchClient,
  selectSideNavWarning,
} from "Layout/redux/selectors";
import RedirectIcon from "Assets/svgs/redirect.svg";
import { selectTenants } from "Features/Tenants/redux/selectors";
import LocalStorageUtility from "Utilities/localstorage";
import { ASTERA_DOWNLOAD_CENTER_URL } from "Constants";

const TopNavBar = () => {
  const showProfile = useAppSelector(selectProfile);
  const showLaunchClient = useAppSelector(selectShowLaunchClient);
  const selectedSideNavWarning = useAppSelector(selectSideNavWarning);
  const tenants = useAppSelector(selectTenants);

  const navigate = useNavigate();

  const isGUID = (str: any) => {
    if (!str) return false;
    const guidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidPattern.test(str);
  };

  const menu = (
    <Menu style={{ width: "278px", padding: "10px" }}>
      <Menu.Item key="userProfile">
        <div className="MenuHeader">
          {isGUID(LocalStorageUtility.getUserName())
            ? LocalStorageUtility.getUserEmail()
            : LocalStorageUtility.getUserName()}
        </div>
        {!isGUID(LocalStorageUtility.getUserName()) && (
          <div className="MenuSubHeader">
            {LocalStorageUtility.getUserEmail() ?? ""}
          </div>
        )}
      </Menu.Item>
      <div className="menu-item-divider" />
      <Menu.Item
        onClick={() => {
          navigate("/profile");
        }}
        className="menuItemText"
        key="editProfile"
      >
        Edit Profile
      </Menu.Item>
      <div className="menu-item-divider" />
      <Menu.Item key="downloadCenter" className="menuItemText">
        <NavLink to={ASTERA_DOWNLOAD_CENTER_URL} target="_blank">
          Download Center
        </NavLink>
      </Menu.Item>
      <div className="menu-item-divider" />
      <Menu.Item
        className="menuItemTextSignout"
        key="logout"
        onClick={() => {
          LocalStorageUtility.deleteUserInfoAndToken();
          window.location.href = "/";
        }}
      >
        Sign out
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="main-header-div">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => {
            navigate("/Home");
          }}
          style={{ cursor: "pointer", marginRight: "20px" }}
        >
          <img className="logo-div" src={AsteraLogo} alt="" />
        </div>
        {/* <div className="top-nav-options">Solutions</div>
        <div className="top-nav-options">Services</div>
        <div className="top-nav-options">Resources</div>
        <div className="top-nav-options">Download Center</div> */}
      </div>

      <div
        style={{ display: "flex", alignItems: "center", marginRight: "1rem" }}
      >
        {showLaunchClient && (
          <div className="top-nav-right">
            <Button
              className="launch-client-button"
              type="primary"
              disabled={selectedSideNavWarning || tenants.length === 0}
              onClick={() => {
                navigate("/launch");
              }}
            >
              <span className="launch-client-button-icon">
                <img src={RedirectIcon} alt="" />
              </span>
              <span className="launch-client-button-text">Launch Client</span>
            </Button>
          </div>
        )}

        {showProfile && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Avatar style={{ cursor: "pointer" }} icon={<UserOutlined />} />
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default TopNavBar;
