import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import PublicRoute from "./Components/Custom/public_route/PublicRoute.index";
import PrivateRoute from "./Components/Custom/private_route/PrivateRoute.index";
import NotFound from "./Components/Basic/not_found/NotFound.index";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "./Config/routes";
import FullPageLoader from "./Components/Basic/full_page_loader/FullPageLoader.index";
import "Styles/index.css";
import { useAppDispatch } from "Store/hooks";
import { setAuthState, setLoginState } from "Features/Auth/redux/slice";
import { setupAxiosInterceptors } from "Utilities/ApiHelpers/ApiHelpers.index";
import { useMsal } from "@azure/msal-react";

const Startup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { instance } = useMsal();

  const storageChange = (event: any) => {
    if (event.key === "access_token") {
      const value =
        event.newValue !== undefined && event.newValue !== null
          ? event.newValue
          : null;

      if (!value) {
        dispatch(setLoginState(1));
        dispatch(setAuthState("login"));
        navigate("/");
      } else if (value !== null && value !== undefined) {
        window.location.href = "/home";
      }
    }
  };
  const originalSetItem = localStorage.setItem;

  localStorage.setItem = function (key: string, value: string) {
    const event: any = new Event("itemInserted");

    event.value = value;
    event.key = key;

    window.dispatchEvent(event);

    originalSetItem.apply(this, [key, value]);
  };

  useEffect(() => {
    window.addEventListener("storage", storageChange, false);
  }, []);

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, [navigate]);

  return (
    <Routes>
      {PRIVATE_ROUTES.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PrivateRoute>
              {route?.layout !== undefined ? (
                <FullPageLoader>
                  <route.layout>
                    {" "}
                    <route.element />
                  </route.layout>
                </FullPageLoader>
              ) : (
                <FullPageLoader>
                  <route.element />
                </FullPageLoader>
              )}
            </PrivateRoute>
          }
        />
      ))}
      {PUBLIC_ROUTES.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <PublicRoute>
              <FullPageLoader>
                <route.element />
              </FullPageLoader>
            </PublicRoute>
          }
        />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Startup;
