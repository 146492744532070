import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createBrowserHistory, History } from "history";

import reducers from "./main_reducer";

export const history: History = createBrowserHistory();


export const store = configureStore({
  reducer: reducers(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools:
    process.env.NODE_ENV !== "production" || process.env.PUBLIC_URL.length > 0,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
