import { Layout } from "antd";
import React, { ReactElement, useState } from "react";
import SideNav from "./SideNav/sideNav.index";
import TopNavBar from "./TopNav/topNav.index";
import { useAppSelector } from "Store/hooks";
import { selectShowSideNav } from "./redux/selectors";
const { Content } = Layout;

const MainLayout = ({ children }: any): ReactElement => {
  const showSideNav = useAppSelector(selectShowSideNav);
  const [isMargin, setIsMargin] = useState(true);
  return (
    <>
    <TopNavBar />
      <Layout hasSider>
        {showSideNav && <SideNav setIsMargin={setIsMargin}/>}
        <Layout className="site-layout">
          <Content style={{marginLeft:isMargin ? '250px' : "75px"}} className="site-layout">{children}</Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
