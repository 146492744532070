import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProfile } from "./thunks";

import { IProfileDTO, ProfileType } from "./types";

const initialState: ProfileType = {
  profile: {
    userId: -1,
    userName: "",
    organizationName: "",
    subDomain: "",
    email: "",
    firstName : "",
    lastName : "",
    organizationId: ""
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        profile: action.payload,
      };
    },
    
  },
  extraReducers(builder) {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
    
  },
});
export const { setProfileState } = profileSlice.actions;
export default profileSlice.reducer;
