import { Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  ITenantSaveUser,
  IUser,
  Platform,
  UserAuthenticationProvider,
} from "../redux/types";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { selectCurrentUser, selectIsUserModalOpen } from "../redux/selectors";
import { setIsUserModalOpen } from "../redux/slice";
import { selectTenants } from "Features/Tenants/redux/selectors";

const AddEditUser = ({ saveEditUser }: any) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isModalOpen = useAppSelector(selectIsUserModalOpen);
  const tenants = useAppSelector(selectTenants);

  const [form] = useForm();

  const onFinish = (values: ITenantSaveUser) => {
    const user: IUser = {
      ...values.user,
      id: currentUser.user.id,
      xmlData: null,
      UserAuthenticationProvider:
        UserAuthenticationProvider.AzureAuthentication,
      emailConfirmed: true,
      isLockedOut: true,
      isActive: false,
      normalizedEmailAddress: values.user.emailAddress.toUpperCase(),
      platform: Platform.Centerprise,
    };
    const payload: ITenantSaveUser = {
      ...values,
      user,
    };
    saveEditUser(payload);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(setIsUserModalOpen(false));
  };
  return (
    <Modal
      title={currentUser.user.id === "-1" ? "Add User" : "Edit User"}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={currentUser.user.id === "-1" ? "Add User" : "Edit User"}
    >
      <p>
        Add users to authorize access to the <b>Tenant</b>
      </p>
      <Form
        style={{ marginTop: "1rem" }}
        name="basic"
        initialValues={currentUser}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <Form.Item<ITenantSaveUser>
          name="tenantName"
          label="Server"
          rules={[{ required: true, message: "Server is required" }]}
        >
          <Select>
            {tenants.map((tenant) => {
              return (
                <Select.Option key={tenant.name} value={tenant.name}>
                  {tenant.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item<ITenantSaveUser>
          label="Username"
          name={["user", "userName"]}
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<ITenantSaveUser>
          label="Name"
          name={["user", "name"]}
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<ITenantSaveUser>
          label="Email"
          name={["user", "emailAddress"]}
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditUser;
