import { ILicense, ITenant } from "../redux/types";

export const licenseValues : ILicense = {
  serverUrl: "",
  version: "",
  path: "",
  key: null,
  username: "",
  organization: "",
  isActivated: false,
  activationCode: null,
  gracePeriod: new Date(),
  trialPeriod: new Date(),
  machineName: "",
  settings: null,
  packageIndex: 0,
  expirationDate: new Date(),
  clientLimit: 0,
  tier: 0,
  features: []
}

export const initialValues : ITenant = {
  description: "",
  serverConnectProperties: null,
  serverDbInfo: null,
  license: licenseValues,
  id: -1,
  createdBy: "",
  updatedBy: "",
  createDtTm: new Date(),
  updateDtTm: new Date(),
  status: "",
  errorInfo: null,
  isActive: true,
  name: "",
  organizationId: ""
};

