import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postRequest,
} from "../../../Utilities/ApiHelpers/ApiHelpers.index";
import { organizationAndTenatStatusIdentifier } from "./indentifier";
import { IGetProfilePayload } from "Features/Profile/redux/types";

export const getOrganizationAndTenantStatus = createAsyncThunk(
  organizationAndTenatStatusIdentifier,
  async (getProfilePayload: IGetProfilePayload) => {
    const response = await postRequest(
      organizationAndTenatStatusIdentifier,
      null,
      true,
      getProfilePayload,
      null
    );
    return response.data;
  }
);
