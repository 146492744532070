import React, { useEffect, useState } from "react";
import { Result, Button, Spin } from "antd";
import { AuthStateEnum } from "../redux/types";
import { setAuthState } from "../redux/slice";
import { useAppDispatch } from "Store/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../redux/thunks";
import { LoadingOutlined } from "@ant-design/icons";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";

const EmailConfirmation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const [verifyToken, setVerifyToken] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verifyParam = searchParams.get("verify");
    if (verifyParam) setVerifyToken(verifyParam);
  }, [location]);
  useEffect(() => {
    if (verifyToken)
      dispatch(verifyEmail(verifyToken))
        .unwrap()
        .then((response) => {
          setVerified(true);
        })
        .catch((err) => {
          console.log(err);
          setVerified(false);
          dispatch(
            setErrorState({
              message: "Error",
              description: err.message,
              placement: "topRight",
              type: "error",
            })
          );
          dispatch(setAuthState(AuthStateEnum.LOGIN));
          navigate("/");
        });
  }, [verifyToken]);
  return (
    <Result
      status="success"
      title="Your Account is Created!"
      subTitle={
        verified
          ? "Your Account is verfied"
          : "Please wait, while we're verifying your account details"
      }
      extra={[
        verified && (
          <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
            <label>
              <a
                style={{ textDecorationLine: "underline" }}
                onClick={() => {
                  dispatch(setAuthState(AuthStateEnum.LOGIN));
                  navigate("/");
                }}
              >
                Click to Proceed
              </a>
            </label>
          </div>
        ),
        !verified && (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        ),
      ]}
    />
  );
};

export default EmailConfirmation;
