import {
  faCheck,
  faDatabase,
  faEllipsis,
  faPencilAlt,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "Features/Tenants/tenants.index.css";
import { useAppDispatch, useAppSelector } from "Store/hooks";
import { setCurrentTenant, setIsModalOpen } from "../../redux/slice";
import {
  deleteTenant,
  getAllTenants,
  saveTenant,
  updateTenant,
} from "../../redux/thunks";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import { Dropdown, MenuProps, Popconfirm } from "antd";
import { ITenant, ITenantFilter } from "../../redux/types";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";
import "Features/Tenants/tenants.index.css";
import { selectProfile } from "Features/Profile/redux/selectors";

export const ActionRenderer = (_: any, record: any) => {
  return <ActionRenderWrapper record={record} />;
};

const ActionRenderWrapper = ({ record }: { record: ITenant }) => {
  const profile = useAppSelector(selectProfile);
  const editTenant = (values: ITenant) => {
    dispatch(setLoadingState(true));
    dispatch(
      values.id === -1
        ? saveTenant({
            tenant: values,
            email: "",
          })
        : updateTenant({ tenant: values, configureRepo: false })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: "Tenant saved successfully",
            placement: "topRight",
            type: "success",
          })
        );
        dispatch(setIsModalOpen(false));
        getTenants();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };
  const getTenants = () => {
    let tenantFilter: ITenantFilter = {
      name: "",
      organizationId: profile?.organizationId,
    };
    dispatch(setLoadingState(true));
    dispatch(getAllTenants(tenantFilter))
      .unwrap()
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };
  const dispatch = useAppDispatch();

  const activateTenant = () => {
    editTenant({ ...record, isActive: true });
  };

  const deactivateTenant = () => {
    editTenant({ ...record, isActive: false });
  };

  const deleteSelectedTenant = (isDelete: boolean) => {
    dispatch(setLoadingState(true));
    dispatch(
      deleteTenant({
        name: record.name,
        isConfigure: isDelete.toString(),
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setErrorState({
            message: "Success",
            description: "Tenant deleted successfully",
            placement: "topRight",
            type: "success",
          })
        );
        getTenants();
      })
      .catch((err: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: err.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  const handleDelete = (isDelete: boolean) => {
    deleteSelectedTenant(isDelete);
  };

  const handleEdit = () => {
    dispatch(setCurrentTenant(record));
    dispatch(setIsModalOpen(true));
  };

  const handleActivation = () => {
    !record.isActive && activateTenant();
  };
  const handleDeactivation = () => {
    record.isActive && deactivateTenant();
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Popconfirm
          title={"Are you sure?"}
          description={"Do you want to delete database as well?"}
          onConfirm={() => {
            handleDelete(true);
          }}
          onCancel={() => {
            handleDelete(false);
          }}
          okText={"Yes"}
          cancelText={"No"}
        >
          <div className={"action-items action-items-red"}>
            <FontAwesomeIcon
              className="action-items-icon action-items-icon-red"
              icon={faTrashAlt}
            />
            Delete
          </div>
        </Popconfirm>
      ),
      danger: true,
      key: "1",
    },
  ];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={faEllipsis}
          rotation={90}
        />
      </Dropdown>
    </div>
  );
};
