import React from "react";
import { Result, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { AuthStateEnum } from "../redux/types";
import { setAuthState } from "../redux/slice";
import { useAppDispatch } from "Store/hooks";
import { useNavigate } from "react-router-dom";

const TenantCreatedPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <Result
      status="success"
      title="Your Account is Created!"
      subTitle="Please check your email to activate your account."
      extra={[
        <Button key="checkEmail" type="primary">
          Check Email
          <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: "8px" }} />
        </Button>,
        <div style={{ marginTop: "1.5rem", textAlign: "center" }}>
          <label>{"Already a member? "}</label>
          <label>
            <a
              style={{ textDecorationLine: "underline" }}
              href="centerprise:open"
            //   onClick={() => {
                
            //     // dispatch(setAuthState(AuthStateEnum.LOGIN));
            //     // navigate("/");
            //   }}
            >
              Login
            </a>
          </label>
        </div>,
      ]}
    />
  );
};

export default TenantCreatedPage;
