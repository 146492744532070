import { useAppDispatch, useAppSelector } from "Store/hooks";
import { Button, Col, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import { getProfile, saveProfile } from "./redux/thunks";
import { selectProfile } from "./redux/selectors";
import {
  IGetProfilePayload,
  IProfileDTO,
  ProfileSaveRequest,
} from "./redux/types";
import { setErrorState } from "Components/Basic/ErrorBoundary/redux/slice";
import {
  setSelectedKeys,
  setSideNavWarning,
  setWarningMessage,
} from "Layout/redux/slice";
import { setLoadingState } from "Components/Basic/full_page_loader/redux/slice";

import styles from "./profile.module.scss";
import { ILocalStorageInfo } from "Common/types";
import LocalStorageUtility from "Utilities/localstorage";

const Profile = () => {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector(selectProfile);
  const [form] = Form.useForm();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(setSelectedKeys(["2"]));
    getProfileData();
  }, []);

  const getProfileData = () => {
    const userInfo: ILocalStorageInfo =
      LocalStorageUtility.getUserInfoAndToken();

    dispatch(setLoadingState(true));
    const payload: IGetProfilePayload = {
      email: userInfo?.user?.emailAddress ?? "",
    };
    dispatch(getProfile(payload))
      .unwrap()
      .then((response: any) => {
        if (form) form.setFieldsValue(response);
        if (!response.organizationName) {
          dispatch(setSideNavWarning(true));
        }
        setShowError(false);
        setErrorMessage("");
      })
      .catch((error: any) => {
        setShowError(true);
        setErrorMessage(error.message);
      })
      .finally(() => {
        dispatch(setLoadingState(false));
      });
  };

  const setUserNameToStorage = (userName: string) => {
    const userInfo: ILocalStorageInfo =
      LocalStorageUtility.getUserInfoAndToken();
    userInfo.user.userName = userName;
    LocalStorageUtility.setUserInfoAndToken(userInfo);
  };

  const saveUserProfile = (values: any) => {
    const data: IProfileDTO = {
      ...profileData,
      ...values,
    };

    const payload: ProfileSaveRequest = {
      data: data,
      params: {
        createTenant: false,
      },
    };

    dispatch(setLoadingState(true));
    dispatch(saveProfile(payload))
      .unwrap()
      .then(() => {
        getProfileData();
        setUserNameToStorage(values.userName);
        dispatch(setSideNavWarning(false));
        dispatch(setWarningMessage(""));
        dispatch(
          setErrorState({
            message: "Success",
            description: "Profile saved successfully",
            placement: "topRight",
            type: "success",
          })
        );
      })
      .catch((error: any) => {
        dispatch(setLoadingState(false));
        dispatch(
          setErrorState({
            message: "Error",
            description: error.message,
            placement: "topRight",
            type: "error",
          })
        );
      });
  };

  return (
    <div style={{ margin: "1.5rem" }}>
      {showError && <div className={styles["error-div"]}>{errorMessage}</div>}

      <div className={styles["profile-settings-card"]}>
        <div className={styles["profile-heading"]}>Profile</div>
        <div className={styles["divider"]} />
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} xs={24} sm={24}>
            <div className={styles["user-info-heading"]}>User Information</div>
            <div className={styles["user-info-text"]}>
              Your personal information needed to identify you in Centerprise
            </div>
          </Col>
          <Col md={16} lg={16} xs={24} sm={24}>
            <Form
              initialValues={profileData}
              onFinish={saveUserProfile}
              layout="vertical"
              form={form}
            >
              <Form.Item
                label={"Username"}
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Please input your unique Username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <Input placeholder="First name" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="last name" />
              </Form.Item>

              <Form.Item label={"Email"} name="email">
                <Input disabled />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "#eaeaea",
            marginBottom: "1rem",
          }}
        ></div>
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} xs={24} sm={24}>
            <div className={styles["user-info-heading"]}>
              Account Information
            </div>
            <div className={styles["user-info-text"]}>
              Your organization related information needed for Centerprise
            </div>
          </Col>
          <Col md={16} lg={16} xs={24} sm={24}>
            <Form
              initialValues={profileData}
              onFinish={saveUserProfile}
              layout="vertical"
              form={form}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter organization name!",
                  },
                ]}
                label={"Organization Name"}
                name="organizationName"
              >
                <Input disabled={!!profileData.organizationName} />
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={showError}
                  style={{ float: "right", background: "#1568c7" }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Profile;
